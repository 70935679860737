<template>
  <b-card
    :class="
      `card-custom gutter-b card-circuled arch-pattern border-0` +
        (sameheight ? ' card-stretch' : '')
    "
    no-body
  >
    <div class="card-body d-flex justify-content-center">
      <div
        :class="`align-self-center ` + (showUser ? 'flex-column w-50' : '')"
        v-if="archetypeId"
      >
        <span class="svg-icon svg-icon-2x svg-icon-white">
          <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Communication/Group.svg-->
          <img
            v-if="svg"
            id="svg1"
            :src="`data:image/svg+xml;base64,${svg}`"
            type="image/svg+xml"
            :class="`border-white arch-${familyType}`"
          />
          <!--end::Svg Icon-->
        </span>
        <span
          class="card-title font-weight-bolder text-dark-75 font-size-h5 mb-0 mt-6 d-block cursor-pointer"
          @click="$router.push({ name: 'Archetype' })"
          v-html="archetype.name.text"
        />
        <!-- <b-button
          v-if="details === true"
          variant="outline-dark"
          @click="$router.push({ name: 'Archetype' })"
          class="btn-pill font-weight-bold text-dark-75 font-size-sm mt-3 border-dark-75 btn-hover-text-white"
          >{{ $t("tables.seeDetail") }}</b-button
        > -->
      </div>
      <div class="flex-column d-flex w-50 align-self-center" v-if="showUser">
        <div class="flex-row align-self-center">
          <avatar :showChange="true" :avatar="avatar" />
        </div>
        <div class="flex-row align-self-center">
          <div class=" align-self-center text-left mb-0">
            <div
              href="javascript:void(0)"
              class="text-center align-self-center font-weight-bolder text-dark-75 font-size-h5 mb-0 mt-6"
            >
              {{ username }}
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </b-card>
</template>

<script>
import Avatar from "@/components/profile/Avatar.component";
import { mapGetters } from "vuex";

export default {
  components: {
    Avatar
  },
  props: {
    username: {
      type: String,
      required: true
    },
    archetype: {
      type: Object,
      required: true
    },
    svg: {
      required: false
    },
    archetypeId: {
      required: false
    },
    details: {
      type: Boolean,
      required: false,
      default: true
    },
    sameheight: {
      type: Boolean,
      default: false,
      required: false
    },
    showUser: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      changeAvatar: false
    };
  },
  computed: {
    ...mapGetters("User", ["avatar"]),
    familyType() {
      if (parseInt(this.archetypeId) >= 92 && parseInt(this.archetypeId) <= 99)
        return "creators";
      else if (
        parseInt(this.archetypeId) >= 100 &&
        parseInt(this.archetypeId) <= 107
      )
        return "defenders";
      else if (
        parseInt(this.archetypeId) >= 76 &&
        parseInt(this.archetypeId) <= 83
      )
        return "explorers";
      else if (
        parseInt(this.archetypeId) >= 84 &&
        parseInt(this.archetypeId) <= 91
      )
        return "influencers";
      else return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.border-white {
  border: solid white 2px;
  border-radius: 10rem !important;
  height: 9.3rem;
}
.arch-pattern {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23E7E7E7' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23BBBBBB'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
</style>
