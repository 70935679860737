var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{class:`card-custom bgi-no-repeat card-stretch gutter-b card-circuled ` +
      (_vm.sessionStatus < 100 ? 'bg-danger text-white ' : 'bg-primary-o-50 ') +
      (_vm.half ? 'card-stretch-half' : ''),style:(_vm.sessionStatus < 100
      ? `background-image: url(` +
        require('@/assets/images/backgrounds/vector-1.png') +
        `);`
      : ''),attrs:{"no-body":""}},[_c('div',{staticClass:"card-header pt-5"},[_c('div',{staticClass:"card-title d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{class:`font-weight-bolder font-size-h2 text-center ` +
              (_vm.sessionStatus < 100 ? 'text-inverse-danger' : '')},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.sessionStatus.${_vm.sessionVariant}.title`))+" ")])]),_c('div')])]),_c('div',{staticClass:"card-body text-left"},[_c('p',{staticClass:"font-weight-bold font-size-h6"},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.sessionStatus.${_vm.sessionVariant}.description`))+" ")]),_c('b-progress',{staticClass:"mt-5",attrs:{"value":_vm.sessionStatus,"animated":true,"variant":'dark',"height":"1rem"}}),(_vm.sessionStatus < 100)?_c('div',{staticClass:"text-center mt-7"},[_c('b-button',{staticClass:"font-weight-bold",attrs:{"variant":"light-dark","pill":""},on:{"click":_vm.goToDetail}},[_vm._v(" "+_vm._s(_vm.$t(`dashboard.sessionStatus.${_vm.sessionVariant}.button`))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }