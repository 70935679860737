<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div class="card-title text-left">
        <div class="card-label font-weight-bolder">
          {{ $t("dashboard.status.title") }}
          <div class="font-size-lg text-muted mt-2">
            {{ $t("dashboard.status.subtitle") }}
          </div>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <!--begin::Chart-->
      <apexchart
        type="radialBar"
        :height="350"
        :options="options"
        :series="series"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Items-->
      <div class="mt-10 mb-5">
        <div class="">
          <!--begin::Item-->
          <div
            class="d-flex align-items-center pb-3 mt-3"
            v-for="col in visibleCols"
            :key="col.type"
          >
            <div class="d-flex align-items-center flex-grow-1">
              <!--begin::Checkbox-->
              <div
                :class="
                  `symbol symbol-45 symbol-light-${col.class} mr-4 flex-shrink-0`
                "
              >
                <div class="symbol-label" style="border-radius: 50px;">
                  <span :class="`svg-icon svg-icon-lg svg-icon-${col.class}`">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="col.type === 'wellbeing'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5 3h1a1 1 0 011 1v16a1 1 0 01-1 1H5a1 1 0 01-1-1V4a1 1 0 011-1zm5 0h1a1 1 0 011 1v16a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" transform="rotate(-19 17.826 11.946)" x="16.326" y="2.946" width="3" height="18" rx="1"/></g></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="col.type === 'cybersecurity'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="#000" opacity=".3" d="M4 4h8v16H4z"/><path d="M6 18h3c.667.114 1 .448 1 1s-.333.886-1 1H4v-5c0-.667.333-1 1-1s1 .333 1 1v3zm12 0v-3c.114-.667.448-1 1-1s.886.333 1 1v5h-5c-.667 0-1-.333-1-1s.333-1 1-1h3zm0-12h-3c-.667-.114-1-.448-1-1s.333-.886 1-1h5v5c0 .667-.333 1-1 1s-1-.333-1-1V6zM6 6v3c-.114.667-.448 1-1 1s-.886-.333-1-1V4h5c.667 0 1 .333 1 1s-.333 1-1 1H6z" fill="#000" fill-rule="nonzero"/></g></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="col.type === 'abs'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 17v-1a1 1 0 00-2 0H0a2 2 0 114 0v1h4a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 01-1-1v-3a1 1 0 011-1z" fill="#000" opacity=".3"/><path d="M12.984 18A7.5 7.5 0 004 11.15V7a2 2 0 012-2h14a2 2 0 012 2v9a2 2 0 01-2 2h-7.016zm6.16-11.16L13 10.148 6.856 6.84a.75.75 0 10-.712 1.32l6.5 3.5c.222.12.49.12.712 0l6.5-3.5a.75.75 0 00-.712-1.32z" fill="#000"/></g></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-if="col.type === 'organization'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><circle fill="#000" opacity=".3" cx="15" cy="17" r="5"/><circle fill="#000" opacity=".3" cx="9" cy="17" r="5"/><circle fill="#000" opacity=".3" cx="7" cy="11" r="5"/><circle fill="#000" opacity=".3" cx="17" cy="11" r="5"/><circle fill="#000" opacity=".3" cx="12" cy="7" r="5"/></g></svg>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <!--end::Symbol-->
              <!--begin::Title-->
              <div
                class="d-flex align-items-left justify-content-between w-auto text-left w-100"
              >
                <div
                  @click="
                    col.link ? $router.push(col.link) : callBot({ bot: absBot })
                  "
                  class="font-size-h5 text-dark-75 font-weight-bolder text-left w-100 is-link"
                >
                  {{ col.title }}
                  <b-icon
                    v-if="col.type === 'cybersecurity'"
                    class="ml-5"
                    icon="info-circle-fill"
                    scale="1.5"
                    variant="warning"
                    id="info"
                    v-b-tooltip.hover="$t('dashboard.awareness.info_icon')"
                  ></b-icon>
                  <div class="w-100">
                    <span
                      style="display: inline-block; border-radius: 5px"
                      v-if="col.showValue"
                      :class="
                        'font-size-sm font-weight-bold mt-1 text-left text-dark-75 w-auto px-2 py-1 ' +
                          getClassByValue(col.type)
                      "
                    >
                      {{ displayValue(col) }} / {{ col.base }}
                    </span>
                    <span
                      style="display: inline-block; border-radius: 5px"
                      v-else
                      :class="
                        'font-size-sm font-weight-bold mt-1 text-left text-dark-75 w-auto py-1 ' +
                          (col.showValue
                            ? getClassByValue(col.type) + 'px-2'
                            : 'px-0')
                      "
                    >
                      {{ getStatusText(col.value, col.type) }}
                    </span>
                  </div>
                </div>
              </div>
              <!--end::Title-->
            </div>
            <div class="ml-2">
              <b-button
                tag="a"
                @click="
                  col.link ? $router.push(col.link) : callBot({ bot: absBot })
                "
                :class="`bg-transparent border-0 svg-icon svg-icon-2x`"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="col.link" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
              </b-button>
            </div>
          </div>
          <!--end::Item-->
        </div>
      </div>
      <!--end::Items-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import _ from "lodash";
import { getClassByValueAndBase } from "@/utils/helpers";
import { getHelpersBots } from "@/api/user/traits.api";
import { BIconInfoCircleFill } from "bootstrap-vue";

export default {
  components: {
    "b-icon": BIconInfoCircleFill
  },
  name: "Status",
  props: {
    wellbeing: {
      required: true
    },
    cybersecurity: {
      required: true
    },
    abs: {
      required: true
    },
    organization: {
      required: true
    }
  },
  data() {
    return {
      absBot: {},
      cols: [
        {
          type: "wellbeing",
          module: ["wellbeing"],
          title: this.$t("dashboard.status.wellbeing"),
          value: this.wellbeing === false ? -1 : this.wellbeing,
          base: 10,
          class: "wellbeing",
          color: "#79c9f7",
          link: { name: "Wellbeing" },
          showValue: true
        },
        {
          type: "cybersecurity",
          module: ["awareness"],
          title: this.$t("dashboard.status.cybersecurity"),
          value: this.cybersecurity === false ? -1 : this.cybersecurity,
          base: 10,
          class: "cybersecurity",
          color: "#ea79c1",
          link: { name: "Awareness" },
          showValue: true
        },
        {
          type: "abs",
          module: ["abs"],
          title: this.$t("dashboard.status.abs"),
          value: this.abs === false ? -1 : this.abs,
          base: 10,
          class: "abs",
          color: "#a7d7a7",
          link:
            this.abs !== false && this.abs < 10 ? { name: "Breaches" } : false,
          chat: this.abs === false || this.abs === 10 ? this.absBot : false
        }
      ]
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Auth", ["hasModule"]),
    visibleCols() {
      return _.filter(this.cols, o => {
        if (this.hasModule(o.module)) return o;
      });
    },
    options() {
      var self = this;
      return {
        chart: {
          height: 350,
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: this.visibleCols.length > 1 ? "50%" : "65%"
            },
            dataLabels: {
              showOn: "always",
              name: {
                show: true,
                fontWeight: "500",
                fontSize: this.visibleCols.length > 1 ? "10px" : "12px",
                color: "#464E5F"
              },
              value: {
                color: "#464E5F",
                fontSize: this.visibleCols.length > 1 ? "16px" : "18px",
                fontWeight: "700",
                offsetY: 2,
                show: true,
                formatter: function(val) {
                  return (
                    (val >= 0 ? self.$n(Math.round(val) / 10) : "?") + "/10"
                  );
                }
              },
              total: {
                show: false
              }
            },
            track: {
              background: "#F3F6F9",
              strokeWidth: "100%"
            }
          }
        },
        colors: _.map(this.visibleCols, o => {
          return o.color;
        }),
        stroke: {
          lineCap: "round"
        },
        labels: _.map(this.visibleCols, o => {
          return o.title;
        })
      };
    },
    series() {
      return _.map(this.visibleCols, o => {
        return o.value * o.base;
      });
    }
  },
  methods: {
    ...mapActions("Chatbot", ["callBot", "addNewBot"]),
    getAbsBot() {
      let surveyAbsTypeId = parseInt(
        this.getConstant("SURVEY_KYMATIO_BREACH_CORPORATE")
      );
      getHelpersBots([surveyAbsTypeId]).then(resp => {
        if (_.size(resp.data.records) > 0) {
          this.addNewBot(_.first(resp.data.records));
          this.absBot = _.first(resp.data.records);
        }
      });
    },
    isVisible(variable) {
      var vari = this[variable];
      return this[vari] === false || this[vari] < 0;
    },
    getStatusText(variable, type) {
      if (variable === false || variable < 0) {
        return this.$t(`dashboard.status.${type}level.empty`);
      } else if (variable < 5.0) {
        return this.$t(`dashboard.status.${type}level.level1`);
      } else if (variable >= 5.0 && variable < 6.5) {
        return this.$t(`dashboard.status.${type}level.level2`);
      } else if (variable >= 6.5 && variable < 8.0) {
        return this.$t(`dashboard.status.${type}level.level3`);
      } else if (variable >= 8.0) {
        return this.$t(`dashboard.status.${type}level.level4`);
      }
    },
    getClassByValue(variable) {
      var vari = this[variable];
      return getClassByValueAndBase(parseInt(vari), 10, true, true);
    },
    displayValue(col) {
      if (col.value >= 0) return this.$n(Math.round(col.value * col.base) / 10);
      else return "?";
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.getAbsBot();
      }
    }
  },
  mounted() {
    if (this.isConstantsActive) {
      let exist = _.find(this.bots, {
        surveyTypeId: parseInt(this.getConstant("SURVEY_KYMATIO_ABSBOT"))
      });

      if (!exist) {
        this.getAbsBot();
      } else {
        this.absBot = exist;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon.svg-icon-wellbeing,
.svg-icon.svg-icon-wellbeing > svg,
.symbol.symbol-light-wellbeing .symbol-label {
  color: #79c9f7;
  background-color: #dff3ff;
  svg g [fill] {
    fill: #79c9f7;
  }
}
.svg-icon.svg-icon-abs,
.svg-icon-abs > svg,
.symbol.symbol-light-abs .symbol-label {
  color: #a7d7a7;
  background-color: #eaf3ea;
  svg g [fill] {
    fill: #a7d7a7;
  }
}
.svg-icon.svg-icon-cybersecurity,
.svg-icon-cybersecurity > svg,
.symbol.symbol-light-cybersecurity .symbol-label {
  color: #ea79c1;
  background-color: #f5e9f1;
  svg g [fill] {
    fill: #ea79c1;
  }
}

.svg-icon.svg-icon-organization,
.svg-icon-organization > svg,
.symbol.symbol-light-organization .symbol-label {
  color: #ea79c1;
  background-color: #f5e9f1;
  svg g [fill] {
    fill: #ea79c1;
  }
}

.is-link {
  cursor: pointer;
}
</style>
