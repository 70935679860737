<template>
  <div id="dashboard" v-if="isProfileLoaded && isUserLoaded">
    <!--begin::Row-->
    <b-row class="mt-0 mt-lg-3">
      <b-col cols xl="4" md="12">
        <gauge
          :title="$t('training.detail.puntuación')"
          :value="progress ? progress * 10 : false"
          :sameheight="true"
          :background="true"
        />
      </b-col>
      <b-col cols xl="4" md="12">
        <ranking
          v-if="hasModule(['gaming_ranking'])"
          :rankings="calculateRankings"
        />
        <archetype
          v-else-if="hasModule(['archetype']) && archetypeId"
          :archetype="archetype"
          :svg="archetypeSvg"
          :archetypeId="archetypeId"
          :sameheight="true"
          :username="fullname"
          :showUser="false"
        />
        <session-status v-else />
      </b-col>
      <b-col cols xl="4" md="12">
        <user :username="fullname" :archetype="archetype" />
      </b-col>
    </b-row>
    <b-row class="mt-0 mt-lg-3" v-if="isProfileLoaded && isUserLoaded">
      <b-col cols xl="4" md="12">
        <sessions-timeline :sessions="sessions" :selectable="true" />
      </b-col>
      <b-col cols xl="4" md="12">
        <status
          :cybersecurity="cybersecurity.average"
          :wellbeing="wellbeing.average"
          :abs="pwnedLevel"
          :organization="organization.average"
          v-if="this.isProfileLoaded"
        />
      </b-col>
      <b-col cols xl="4" md="12">
        <alerts :data="alerts" type="user" />
      </b-col>
    </b-row>
    <!--end::Row-->
    <!--begin::Row-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Status from "@/components/dashboard/Status.component";
import Alerts from "@/components/dashboard/Alerts.component";
import SessionsTimeline from "@/components/dashboard/SessionsTimeline.component";
import Gauge from "@/components/profile/Gauge.component";
import User from "@/components/profile/User.component";
import Ranking from "@/components/users/charts/Ranking.component";
import Archetype from "@/components/profile/Archetype.component";
import SessionStatus from "@/components/profile/SessionStatus.component";
import _ from "lodash";

export default {
  name: "Dashboard",
  components: {
    User,
    Status,
    SessionsTimeline,
    Alerts,
    Gauge,
    Ranking,
    Archetype,
    SessionStatus
  },
  data() {
    return {
      test: false,
      rankingsCalculated: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", [
      "isUserLoaded",
      "sessions",
      "fullname",
      "archetypeSvg",
      "archetypeId"
    ]),
    ...mapGetters("Profile", [
      "isProfileLoaded",
      "isAchievementsLoaded",
      "wellbeing",
      "cybersecurity",
      "organization",
      "pwnedLevel",
      "archetype",
      "progress",
      "alerts",
      "rankings"
    ]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Auth", ["hasModule"]),
    familyType() {
      if (parseInt(this.archetypeId) >= 92 && parseInt(this.archetypeId) <= 99)
        return "creators";
      else if (
        parseInt(this.archetypeId) >= 100 &&
        parseInt(this.archetypeId) <= 107
      )
        return "defenders";
      else if (
        parseInt(this.archetypeId) >= 76 &&
        parseInt(this.archetypeId) <= 83
      )
        return "explorers";
      else if (
        parseInt(this.archetypeId) >= 84 &&
        parseInt(this.archetypeId) <= 91
      )
        return "influencers";
      else return "";
    },
    calculateRankings() {
      this.initializeRankingsCalculated();
      const cyberName = "cyber";
      if (this.rankings.length > 0) {
        const hasCyber = _.some(this.rankings, { name: cyberName });
        this.rankings.forEach((ranking, index) => {
          this.rankingsCalculated.push({
            name: this.getRankingName(ranking.name),
            value: Number(
              Math.round(
                100 -
                  ((ranking.value.position + 1) / ranking.value.intervals) * 100
              )
            ),
            position: Math.round(
              ((ranking.value.position + 1) / ranking.value.intervals) * 100
            ),
            visible: index == 0 ? true : false,
            index:
              hasCyber && ranking.name === cyberName
                ? 0
                : hasCyber
                ? index + 1
                : index
          });
        });
      }
      let sortedRankings = _.orderBy(this.rankingsCalculated, "index", "asc");
      return sortedRankings;
    }
  },
  methods: {
    ...mapActions("Profile", ["loadProfile", "loadAchievements"]),
    ...mapActions("Auth", ["changeDefaultView"]),
    getRankingName(name) {
      switch (name) {
        case "assets":
          return this.$tc("dashboard.rankings.assets.title");
        case "global":
          return this.$tc("dashboard.rankings.global.title");
        case "cyber":
          return this.$tc("dashboard.rankings.cyber.title");
      }
    },
    initializeRankingsCalculated() {
      this.rankingsCalculated = [];
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) {
      this.loadProfile();
      this.changeDefaultView("Dashboard");
    }
    if (this.isConstantsActive && !this.isAchievementsLoaded) {
      this.loadAchievements();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile(true);
      }
    },
    isProfileLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadProfile();
      }
    },
    isAchievementsLoaded(value) {
      if (this.isConstantsActive && value === false) {
        this.loadAchievements();
      }
    }
  }
};
</script>
