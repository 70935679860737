<template>
  <b-card class="card-custom gutter-b card-circuled card-stretch" no-body>
    <!--begin::Body-->
    <div
      class="card-body align-items-center d-flex flex-column justify-content-center"
    >
      <div class="d-flex flex-column align-self-center">
        <avatar :showChange="true" :avatar="avatar" />
      </div>
      <div class="d-flex flex-column">
        <div class=" align-self-center text-left mb-0">
          <div
            href="javascript:void(0)"
            class="text-center align-self-center font-weight-bolder text-dark-75 font-size-h5 mb-0 mt-6"
          >
            <div>
              {{ username }}
            </div>
            <div
              v-if="hasArchetype"
              class="text-success cursor-pointer"
              @click="$router.push({ name: 'Archetype' })"
            >
              <span v-if="hasArchetype">
                <span v-html="archetype.name.text" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import Avatar from "@/components/profile/Avatar.component";

export default {
  name: "User",
  components: {
    Avatar
  },
  props: {
    username: {
      type: String,
      required: true
    },
    archetype: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      changeAvatar: false
    };
  },
  computed: {
    ...mapGetters("User", ["avatar"]),
    hasArchetype() {
      return !_.isEmpty(this.archetype);
    }
  }
};
</script>
