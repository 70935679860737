<template>
  <b-card
    :class="
      `card-custom bgi-no-repeat card-stretch gutter-b card-circuled ` +
        (sessionStatus < 100 ? 'bg-danger text-white ' : 'bg-primary-o-50 ') +
        (half ? 'card-stretch-half' : '')
    "
    no-body
    :style="
      sessionStatus < 100
        ? `background-image: url(` +
          require('@/assets/images/backgrounds/vector-1.png') +
          `);`
        : ''
    "
  >
    <div class="card-header pt-5">
      <!--begin::Title-->
      <div class="card-title d-flex flex-column">
        <div class="flex-grow-1">
          <div
            :class="
              `font-weight-bolder font-size-h2 text-center ` +
                (sessionStatus < 100 ? 'text-inverse-danger' : '')
            "
          >
            {{ $t(`dashboard.sessionStatus.${sessionVariant}.title`) }}
          </div>
        </div>
        <div></div>
      </div>
      <!--end::Title-->
    </div>
    <div class="card-body text-left">
      <p class="font-weight-bold font-size-h6">
        {{ $t(`dashboard.sessionStatus.${sessionVariant}.description`) }}
      </p>
      <b-progress
        :value="sessionStatus"
        :animated="true"
        :variant="'dark'"
        height="1rem"
        class="mt-5"
      />
      <div class="text-center mt-7" v-if="sessionStatus < 100">
        <b-button
          class="font-weight-bold"
          variant="light-dark"
          pill
          @click="goToDetail"
        >
          {{ $t(`dashboard.sessionStatus.${sessionVariant}.button`) }}
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SessionStatus",
  props: {
    half: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("Profile", ["alerts", "pendingInteractions"]),
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", ["sessions"]),
    sessionStatus() {
      var value = 100;
      var interactions = _.filter(
        this.alerts,
        alert => alert.type === "interaction" && alert.levelNo < 3
      );
      var breaches = _.filter(
        this.alerts,
        alert => alert.type === "breach" && alert.levelNo < 3
      );
      var assets = _.filter(
        this.alerts,
        alert => alert.type === "assets" && alert.levelNo < 3
      );

      var alerts = _.concat(interactions, breaches, assets);

      _.each(alerts, alert => {
        let multiplier = 1;
        if (alert.type === "interaction") {
          multiplier = 40;
        } else if (alert.type === "breach") {
          multiplier = 2;
        } else if (alert.type === "assets") {
          multiplier = 1;
        }

        value = value - (multiplier / alert.levelNo) * alert.total;
      });

      return value;
    },
    sessionVariant() {
      return this.sessionStatus < 100 ? "danger" : "success";
    }
  },
  methods: {
    ...mapActions("Chatbot", ["activate"]),
    goToDetail() {
      const alert = _.first(this.alerts);

      if (alert.type === "interaction" && this.pendingInteractions.length > 0) {
        let survey = _.first(this.pendingInteractions);
        this.activate(survey.surveyEntityId);
      } else {
        this.$router.push(alert.to);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-image-card {
  background-color: #ff0000;
  background-size: cover;
  background-position-x: right;
  background-repeat: no-repeat;
  color: white;
}
</style>
